import Response from './Response'
import _ from 'lodash'

/**
 * Este modelo generaliza respostas retornadas por relatórios
 */
export default class ReportResponse extends Response {
    constructor(response = {}) {
        super(response)
    }

    /**
     * Preenche os dados fornecidos no construtor.
     *
     * @override
     * @param {object} data dados do construtor
     */
    _fill(data = {}) {
        this.data  = data.data
        this.count = data.count
    }

    /**
     * Define os campos que são esperados nesta resposta
     *
     * @override
     * @returns {string[]}
     */
    expectedFields() {
        return ['data', 'count']
    }

    /**
     * Avalia se o objeto passado como parâmetro é uma instância de ReportGrouping do backend
     *
     * @argument {object} item objeto a ser avaliado
     * @returns {boolean}
     */
    static isReportGrouping(item) {
        // Poderia ser mais específico, mas tem alguns que não tem label e/ou key
        return item && item.count && !!item.model_count
    }

    /**
     * Avalia se o objeto passado como parâmetro é uma instância de ReportGrouping do backend
     *
     * @argument {object} item objeto a ser avaliado
     * @returns {boolean}
     */
    static isReportGroupingOfReportGroups(item) {
        // Poderia ser mais específico, mas tem alguns que não tem label e/ou key
        return item && item.count != item.model_count
    }

}