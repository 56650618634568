var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _c("v-icon", { staticClass: "mr-3" }, [_vm._v(_vm._s(_vm.icon))]),
          _vm._v(" " + _vm._s(_vm.title) + " ")
        ],
        1
      ),
      _c(
        "v-card-text",
        [_c("p", [_vm._v(_vm._s(_vm.description))]), _vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }