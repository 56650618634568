import _ from 'lodash'

/**
 * Este modelo generaliza respostas recebidas do servidor
 */
export default class Response {
    constructor(response = {}) {
        this._fill(response.data)
    }

    /**
     * Preenche os dados fornecidos no construtor.
     * Obs: Precisa ser implementado nas classes filhas!
     *
     * @param {object} data dados do construtor
     */
    _fill(data = {}) {
        throw new Error('Must be implemented in child classes.')
    }

    /**
     * Define os campos que são esperados nesta resposta
     *
     * @returns {string[]}
     */
    expectedFields() {
        return []
    }

    /**
     * Converte os dados deste modelo em um objeto
     *
     * @returns {object}
     */
    getData() {
        return _.pick(this.data, this.expectedFields())
    }
}