/**
 * Este modelo generaliza as classes de repositório
 */
export default class Repository {

    constructor(company_id) {
        this.company_id = company_id
    }

    /**
     * Define o modelo sobre o qual este repositório se refere
     * Obs: Precisa ser implementado nas classes filhas!
     *
     * @returns {BaseModel}
     */
    getModel() {
        throw new Error('Must be implemented in child classes.')
    }
}