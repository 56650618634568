var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mt-3 pb-3" },
    [
      _c(
        "v-card-title",
        [
          _c("v-icon", { staticClass: "mr-3" }, [_vm._v("fas fa-table")]),
          _vm._v(" " + _vm._s(_vm.title || _vm.defaultTitle) + " "),
          _c("v-spacer"),
          _c(
            "v-tooltip",
            {
              directives: [
                {
                  name: "can",
                  rawName: "v-can:export",
                  value: "Report",
                  expression: "'Report'",
                  arg: "export"
                }
              ],
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "float-right",
                              attrs: {
                                outlined: "",
                                color: "grey darken-3",
                                disabled: _vm.isExportDisabled
                              },
                              on: { click: _vm.exportReport }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-2", attrs: { small: "" } },
                            [_vm._v("fas fa-file-export")]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("views.reports.commons.content.export")
                              ) +
                              " "
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("views.reports.commons.content.export_tooltip"))
                )
              ])
            ]
          )
        ],
        1
      ),
      _vm.hasError
        ? _c(
            "v-card-text",
            [
              _c("v-alert", { staticClass: "ma-0", attrs: { type: "error" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("views.reports.commons.content.error")) +
                    " "
                )
              ])
            ],
            1
          )
        : [
            _c(
              "v-card-text",
              { staticClass: "pa-0" },
              [
                _c("SimplePaginator", {
                  attrs: {
                    pagination: _vm.pagination,
                    items: _vm.computedItems,
                    hidePerPage: "",
                    isLastPage: _vm.isLastPage,
                    perPageDisabled: _vm.isPaginatorDisabled
                  },
                  on: {
                    "update:pagination": function($event) {
                      _vm.pagination = $event
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "v-data-table",
              _vm._g(
                _vm._b(
                  {
                    attrs: {
                      items: _vm.computedItemsWithLabels,
                      loading: _vm.loading,
                      "disable-sort": "",
                      "disable-pagination": "",
                      "hide-default-footer": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function(props) {
                            return [
                              _vm.isReportGrouping(props.item)
                                ? [
                                    _c(
                                      "tr",
                                      [
                                        _c(
                                          "td",
                                          {
                                            class: _vm.getClassFromDepth(
                                              props.item
                                            ),
                                            attrs: {
                                              colspan: _vm.hasSlot("group-item")
                                                ? 1
                                                : _vm.attrs.headers.length - 1
                                            }
                                          },
                                          [
                                            _vm._l(
                                              (props.item.depth || 1) - 1,
                                              function(i) {
                                                return _c("span", {
                                                  key: i,
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    small: "",
                                                    color: "blue lighten-2"
                                                  }
                                                })
                                              }
                                            ),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-1",
                                                attrs: {
                                                  small: "",
                                                  color: "blue lighten-2"
                                                }
                                              },
                                              [_vm._v("fas fa-caret-right")]
                                            ),
                                            _vm._t(
                                              "label-depth-" + props.item.depth,
                                              [
                                                _c(
                                                  "b",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getComputedLabel(
                                                          props.item.label
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              null,
                                              props
                                            )
                                          ],
                                          2
                                        ),
                                        _vm._t(
                                          "group-item",
                                          null,
                                          null,
                                          Object.assign({}, props, {
                                            tdClass: _vm.getClassFromDepth(
                                              props.item
                                            )
                                          })
                                        ),
                                        !_vm.hideCount
                                          ? _c(
                                              "td",
                                              {
                                                class: _vm.getClassFromDepth(
                                                  props.item
                                                )
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-italic float-right"
                                                  },
                                                  [
                                                    _vm.isReportGroupingOfReportGroups(
                                                      props.item
                                                    )
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "views.reports.commons.content.nested_group_count",
                                                                  {
                                                                    count_groups: _vm.$tc(
                                                                      "views.reports.commons.content.nested_group_count_groups",
                                                                      props.item
                                                                        .count
                                                                    ),
                                                                    count_results: _vm.$tc(
                                                                      "views.reports.commons.content.nested_group_count_results",
                                                                      props.item
                                                                        .model_count
                                                                    )
                                                                  }
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$tc(
                                                                  "views.reports.commons.content.group_count",
                                                                  props.item
                                                                    .model_count
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ])
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ]
                                : _vm._t("item", null, null, props)
                            ]
                          }
                        },
                        {
                          key: "footer",
                          fn: function() {
                            return [
                              _c("SimplePaginator", {
                                staticClass: "mt-3",
                                attrs: {
                                  pagination: _vm.pagination,
                                  items: _vm.computedItems,
                                  isLastPage: _vm.isLastPage,
                                  perPageDisabled: _vm.isPaginatorDisabled,
                                  hidePerPage: _vm.isPaginatorDisabled
                                },
                                on: {
                                  "update:pagination": function($event) {
                                    _vm.pagination = $event
                                  }
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  },
                  "v-data-table",
                  _vm.attrs,
                  false
                ),
                _vm.listeners
              )
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }