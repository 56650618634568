<template>
    <v-card>
        <v-card-title>
            <v-icon class="mr-3">{{ icon }}</v-icon>
            {{ title }}
        </v-card-title>
        <v-card-text>
            <p>{{ description }}</p>

            <slot></slot>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: 'ReportHeader',
    props: {
        icon       : { required: true, type: String },
        title      : { required: true, type: String },
        description: { required: true, type: String },
    },
}
</script>