<template>
    <v-card class="mt-3 pb-3">
        <v-card-title>
                <v-icon class="mr-3">fas fa-table</v-icon>
                {{ title || defaultTitle }}
                <v-spacer></v-spacer>

                <!-- Descomentar quando tiver exportação de relatórios [FID-463] -->
                <v-tooltip bottom v-can:export="'Report'">
                    <template #activator="{ on, attrs }">
                        <v-btn outlined class="float-right"
                            v-on="on" v-bind="attrs"
                            @click="exportReport"
                            color="grey darken-3"
                            :disabled="isExportDisabled"
                        >
                            <v-icon small class="mr-2">fas fa-file-export</v-icon>
                            {{ $t('views.reports.commons.content.export') }}
                        </v-btn>
                    </template>
                    <span>{{ $t('views.reports.commons.content.export_tooltip') }}</span>
                </v-tooltip>
        </v-card-title>

        <v-card-text v-if="hasError">
            <v-alert type="error" class="ma-0">
                {{ $t('views.reports.commons.content.error') }}
            </v-alert>
        </v-card-text>

        <template v-else>
            <!-- Header da tabela -->
            <v-card-text class="pa-0">
                <SimplePaginator
                    :pagination.sync="pagination"
                    :items="computedItems"
                    hidePerPage
                    :isLastPage="isLastPage"
                    :perPageDisabled="isPaginatorDisabled"
                />
            </v-card-text>

            <!-- Conteúdo da tabela -->
            <v-data-table
                v-on="listeners"
                v-bind="attrs"
                :items="computedItemsWithLabels"
                :loading="loading"
                disable-sort
                disable-pagination
                hide-default-footer
            >

                <!-- Conteúdo de uma linha -->
                <template #item="props">
                    <!-- Se tem label, é de um agrupamento do backend -->
                    <template v-if="isReportGrouping(props.item)">
                        <tr>
                            <td :class="getClassFromDepth(props.item)" :colspan="hasSlot('group-item') ? 1 : attrs.headers.length - 1">
                                <span small color="blue lighten-2" class="mr-2" v-for="i in (props.item.depth || 1) - 1" :key="i"></span>
                                <v-icon small color="blue lighten-2" class="mr-1">fas fa-caret-right</v-icon>

                                <!-- Texto descritivo do agrupamento -->
                                <slot :name="`label-depth-${props.item.depth}`" v-bind="props">
                                    <b class="font-weight-bold">{{ getComputedLabel(props.item.label) }}</b>
                                </slot>
                            </td>
                            <slot name="group-item" v-bind="{ ...props, tdClass: getClassFromDepth(props.item) }"></slot>
                            <td :class="getClassFromDepth(props.item)" v-if="!hideCount">
                                <span class="font-italic float-right">
                                    <span v-if="isReportGroupingOfReportGroups(props.item)">
                                        {{ $t('views.reports.commons.content.nested_group_count', {
                                            count_groups : $tc('views.reports.commons.content.nested_group_count_groups', props.item.count),
                                            count_results: $tc('views.reports.commons.content.nested_group_count_results', props.item.model_count),
                                        }) }}
                                    </span>
                                    <span v-else>
                                        {{ $tc('views.reports.commons.content.group_count', props.item.model_count) }}
                                    </span>
                                </span>
                            </td>
                        </tr>
                    </template>

                    <!-- Se não tem, assume o formato especificado no componente pai -->
                    <slot v-else name="item" v-bind="props"></slot>
                </template>

                <!-- Footer da tabela -->
                <template #footer>
                    <SimplePaginator
                        class="mt-3"
                        :pagination.sync="pagination"
                        :items="computedItems"
                        :isLastPage="isLastPage"
                        :perPageDisabled="isPaginatorDisabled"
                        :hidePerPage="isPaginatorDisabled"
                    />
                </template>

            </v-data-table>
        </template>
    </v-card>
</template>
<script>
import SimplePaginator from '@/shared/components/paginators/SimplePaginator'
import ReportResponse  from '@/models/ReportResponse'
import HasSlotMixin    from '@/mixins/HasSlot'
// import BaseModel       from '@/models/BaseModel'

export default {
    props: {
        title        : String,
        hasError     : Boolean,
        loading      : Boolean,
        hideCount    : Boolean,
        disableExport: Boolean,
        count        : Boolean,
        model        : {
            // type: BaseModel,
        },

        pagination: {
            required: true,
            type: Object,
        },
        reportResponse: {
            type: ReportResponse,
        },
        // Contém funções e mapping de tradução para labels do backend
        i18nMapping: {
            type: Object,
            default: () => ({}),
        },
    },
    mixins: [ HasSlotMixin ],
    components: { SimplePaginator },
    data: vm => ({
        defaultTitle: vm.$t('views.reports.commons.content.title'),
    }),
    methods: {
        groupAndHydrate(reportGrouping = {}) {
            if (this.$lodash.isEmpty(reportGrouping))
                return []
            let finalArray = []

            if (reportGrouping.key == 'root' && reportGrouping.model_count) {
                finalArray.push(this.$lodash.omit(reportGrouping, 'data'))
            }

            // Verifica se o primeiro item tem formato de agrupamento do backend
            for (let groupingData of reportGrouping.data) {
                let toPush = this.$lodash.omit(groupingData, 'data')
                let isGrouping = this.isReportGrouping(groupingData)
                if (isGrouping) {
                    // Verifica se precisa substituir a label
                    let mapping = this.i18nMapping[toPush.misc]
                    toPush.label = this.applyMapping(mapping, toPush.label)
                    // console.log('toPush.label', toPush.label)
                } else if (this.model) {
                    toPush = new this.model(toPush)
                }

                finalArray.push(toPush)

                if (isGrouping) {
                    finalArray.push(...this.groupAndHydrate(groupingData))
                }
            }

            return finalArray
        },
        isReportGrouping(item) {
            return ReportResponse.isReportGrouping(item)
        },
        isReportGroupingOfReportGroups(item) {
            return ReportResponse.isReportGroupingOfReportGroups(item)
        },
        /**
         * Considera um mapping da props e retorna seu valor mapeado
         *
         * @returns {string}
         */
        applyMapping(mapping, label) {
            if (!mapping)
                return label

            if (typeof mapping == 'function')
                return mapping(label)
            if (typeof mapping == 'object')
                return mapping[label]

            return label
        },
        getClassFromDepth(item) {
            const defaultValue = 'grey lighten-5'
            const map = {
                0: 'grey lighten-2',
                1: 'grey lighten-3',
                2: 'grey lighten-4',
            }
            return map[item.depth] || defaultValue
        },
        getComputedLabel(label) {
            const unknownLabel = this.$t('views.reports.commons.content.unknown_label')
            const map = {
                'UNKNOWN'   : unknownLabel,
                'ROOT_LABEL': this.$t('views.reports.commons.content.root_label'),
            }

            return map[label] || label || unknownLabel
        },
        exportReport() {
            this.$emit('export')
        },
    },
    computed: {
        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { ...listeners } = this.$listeners
            return listeners
        },

        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { items, ...attrs } = this.$attrs
            return { ...attrs }
        },

        computedItemsWithLabels() {
            return this.reportResponse ? this.groupAndHydrate(this.reportResponse.data) : []
        },
        computedItems() {
            return this.computedItemsWithLabels
                .filter(f => f && !this.isReportGrouping(f))
        },

        isPaginatorDisabled() {
            return this.count
        },

        isLastPage() {
            // let lastItem = this.pagination.page * this.pagination.per_page
            // return this.count.Count == lastItem
            // [TODO] [FID-871] refazer
            return false
        },

        isExportDisabled() {
            return !this.computedItemsWithLabels.length || this.loading || this.disableExport
        },
    },
}
</script>