import SharedRepository from '@/shared/repositories/Repository'

/**
 * Este modelo generaliza as classes de repositório
 */
export default class Repository extends SharedRepository {

    constructor(company_id) {
        super(...arguments)
    }

}